<template>
  <div class="wrap" v-loading="loading">
    <el-form ref="form" :model="commissionForm" :rules="rules" label-width="90px">
      <!-- 顶部按钮 -->
      <div class="btnTOP">
        <el-button type="primary" size="mini" @click="submitForm(false)" :disabled="disabled">保存
        </el-button>
        <el-button type="primary" size="mini" @click="submitForm(true)" :disabled="disabled">保存并新增
        </el-button>
        <el-button type="primary" size="mini" @click="submitAudit" :disabled="disabled">审核
        </el-button>
        <el-button @click="getQuit" size="mini">退出</el-button>
      </div>
      <cardTitleCom cardTitle="基本信息" :billStatus="commissionForm.billStatus">
        <template slot="cardContent">
          <div class="tableContentTop x-w">
            <el-form-item label="方案编号" prop="billNo">
              <el-input v-model="commissionForm.billNo" size="mini" maxlength="20" disabled></el-input>
            </el-form-item>
            <el-form-item label="方案名称" prop="billName">
              <el-input v-model="commissionForm.billName" size="mini" maxlength="40" :disabled="disabled"></el-input>
            </el-form-item>
            <el-form-item label="有效时间" prop="datetime">
              <el-date-picker size="mini" v-model="commissionForm.datetime" type="datetimerange"
                start-placeholder="开始时间" end-placeholder="结束时间" :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss" :disabled="disabled" :picker-options="forbiddenTime">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="分销模式" prop="distributionType">
              <el-select v-model="commissionForm.distributionType" placeholder="请选择分销模式" size="mini"
                :disabled="disabled">
                <el-option label="一级分销" :value="1"></el-option>
                <el-option label="二级分销" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注">
              <el-input class="inputRemark" v-model="commissionForm.billRemark" type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" :autosize="{ minRows: 1, maxRows: 5 }" size="mini"
                :disabled="disabled" />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="提成类型">
        <template slot="cardContent">
          <div class="tableContent marL15">
            <!-- 按销售提成 -->
            <div>
              <div v-for="(item,index) in commissionForm.vipDistributionManCommissionDetailItems" :key="index">
                <div class="marB10 x-f">
                  <div style="margin-right:10px;font-size:14px">
                    <i style="color: #ff4949">* </i>
                    <span>分销员等级</span>
                  </div>
                  <el-form-item prop="distributionLevelId">
                    <SelectRemote @change="distributionLevelChnage" v-model="item.distributionLevelId" :option="
                      $select({
                      key: 'listDistributionManLeve',
                      option: {
                      option: {
                        disabled: disabled,
                        showItem: [
                          {
                            distributionLevelId: item.distributionLevelId,
                            distributionLevelName: item.distributionLevelName,
                          },
                        ],
                      },
                    },
                  }).option
                " />
                  </el-form-item>
                  <div style="margin:0 10px;font-size:14px">
                    <i style="color: #ff4949">* </i>
                    <span>提成范围</span>
                  </div>
                  <el-form-item prop="goodsRangeType">
                    <el-select v-model="item.goodsRangeType" placeholder="请选择提成范围" filterable clearable
                      @change="goodsRangeTypeChange($event,index)" size="mini" :disabled="disabled">
                      <el-option label="全部商品" :value="0"></el-option>
                      <el-option label="指定商品" :value="1"></el-option>
                      <el-option label="指定类别" :value="2"></el-option>
                      <el-option label="指定品牌" :value="3"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-button size="mini" type="primary" icon="el-icon-plus" @click="addDistributionLevel(index)"
                    style="margin-left:20px">添加等级</el-button>
                  <el-button size="mini" type="danger" icon="el-icon-delete"
                    @click="delDistributionLevel(index)">删除等级</el-button>
                </div>
                <el-table class="marB10" v-if="item.goodsRangeType != undefined" v-loading="loadingTable"
                  ref="multipleTable" tooltip-effect="dark" :data="item.billGoodsDetailItems" border max-height="500">
                  <el-table-column label="序号" type="index" width="80" class-name="allowDrag" align="center" />
                  <el-table-column align="center" label="操作" width="120" v-if="!disabled && item.goodsRangeType != 0">
                    <template v-slot="scope">
                      <i @click="row('push', scope.$index, index)" class="el-icon-circle-plus operatePush"></i>
                      <i @click="row('del', scope.$index, index)" class="el-icon-remove operateDel"></i>
                    </template>
                  </el-table-column>
                  <el-table-column label="类型" align="center" prop="lineType" width="130"
                    v-if="item.goodsRangeType != 0">
                    <template slot-scope="scope">
                      <dict-tag :options="
                          dict.type.vip_applicable_commodities_appoint_type
                        " :value="scope.row.lineType" />
                    </template>
                  </el-table-column>

                  <el-table-column label="编码" width="180" align="center" prop="lineNo" v-if="item.goodsRangeType != 0">
                    <template slot="header">
                      <i style="color: #ff4949">* </i>
                      <span>编码</span>
                    </template>
                    <template slot-scope="scope">
                      <el-form-item>
                        <el-input size="mini" v-model="scope.row.lineNo" :disabled="disabled">
                          <i slot="suffix" class="el-icon-more more" @click="selectLineType(index,item)"></i>
                        </el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="名称" align="center" prop="lineName" width="130"
                    v-if="item.goodsRangeType != 0" />
                  <el-table-column label="单位" align="center" prop="unitId" width="170" v-if="item.goodsRangeType == 1">
                    <template slot="header">
                      <i style="color: #ff4949">* </i>
                      <span>单位</span>
                    </template>
                    <template slot-scope="scope">
                      <el-form-item>
                        <el-select size="mini" style="width: 146px" v-model="scope.row.unitId" placeholder="请选择单位"
                          :disabled="disabled">
                          <el-option v-for="dict in scope.row.units" :key="dict.unitId" :label="dict.unitName"
                            :value="dict.unitId" />
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="提成方式" :width="commissionForm.distributionType===2?490:325" align="center"
                    prop="commissionCalcType">
                    <template slot="header">
                      <i style="color: #ff4949">* </i>
                      <span>提成方式</span>
                    </template>
                    <template slot-scope="scope">
                      <div class="x-x">
                        <el-form-item>
                          <el-select size="mini" class="marR10" style="width: 146px"
                            v-model="scope.row.commissionCalcType" @change="commissionCalcTypeChange(scope.row,1)"
                            placeholder="请选择提成方式" clearable :disabled="disabled">
                            <el-option label="销售提成率(%)" :value="1"></el-option>
                            <el-option label="按数量提成(个)" :value="2"></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item v-if="scope.row.commissionCalcType == 1">
                          <el-input size="mini" class="inputWidth sWidth" v-model="scope.row.commissionSaleRate1"
                            oninput="value=value.replace(/[^0-9.]/g,'')" clearable :disabled="disabled"
                            placeholder="一级销售提成率">
                          </el-input>
                        </el-form-item>
                        <el-form-item v-if="scope.row.commissionCalcType == 2">
                          <el-input size="mini" class="inputWidth sWidth" v-model="scope.row.commissionSaleOneMoney1"
                            oninput="value=value.replace(/[^0-9.]/g,'')" clearable :disabled="disabled"
                            placeholder="一级按数量提成">
                          </el-input>
                        </el-form-item>
                        <el-form-item v-if="scope.row.commissionCalcType == 1&&commissionForm.distributionType===2">
                          <el-input size="mini" class="inputWidth sWidth" oninput="value=value.replace(/[^0-9.]/g,'')"
                            v-model="scope.row.commissionSaleRate2" clearable :disabled="disabled"
                            style="margin-left:10px" placeholder="二级销售提成率">
                          </el-input>
                        </el-form-item>
                        <el-form-item v-if="scope.row.commissionCalcType == 2&&commissionForm.distributionType===2">
                          <el-input size="mini" class="inputWidth sWidth" v-model="scope.row.commissionSaleOneMoney2"
                            oninput="value=value.replace(/[^0-9.]/g,'')" clearable :disabled="disabled"
                            style="margin-left:10px" placeholder="二级按数量提成">
                          </el-input>
                        </el-form-item>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" prop="goodsNo" />
                </el-table>
              </div>
            </div>
            <!-- 按适用对象 -->
            <div>
              <el-form-item label="适用会员" prop="vipLevelRangeType" label-width="80px">
                <el-radio-group v-model="commissionForm.vipLevelRangeType" :disabled="disabled">
                  <el-radio :label="0">全部会员</el-radio>
                  <el-radio :label="1">全部会员+排除</el-radio>
                  <el-radio :label="2">指定会员</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 指定会员区域 -->
              <div class="selectVip" v-if="vipTable">
                <div class="vipBtn">
                  <el-button size="mini" @click="openDialog('vip')" :disabled="disabled">选择会员
                  </el-button>
                  <el-button size="mini" @click="openDialog('vipLevel')" :disabled="disabled">选择会员等级
                  </el-button>
                </div>
                <!-- 会员表格 -->
                <comTable class="marB10" :columnLabel="vipTableLable" :tableData="commissionForm.billLevelDetailItems"
                  @delTableRow="delTableRow($event, 'vip')" :disabled="disabled">
                  <!-- 会员表格插入类型 -->
                  <template #tableMiddle>
                    <el-table-column label="类型" align="center" prop="lineType" width="123">
                      <template slot-scope="scope">
                        <dict-tag :options="dict.type.member_type" :value="scope.row.lineType" />
                      </template>
                    </el-table-column>
                  </template>
                </comTable>
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>
  
  <script>
import { deepCopy } from "@/utils";
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import Dialog from "@/components/Dialog";
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import { getTimeDate } from '@/utils/newDate' //单据日期
import { getNewBillNo } from "@/utils/numFormatting"; //获取编号
import {
  commissionAddAPI,
  commissionDetailAPI,
  commissionUpdateAPI,
  updateBillStatus
} from "@/api/vip/distribution/commissionCase"; //分销员提成设置
import comTable from "@/views/components/com-table"; //活动规则表格组件
export default {
  name: 'commissionDetailVip',
  dicts: ['vip_applicable_commodities_appoint_type', "member_type"],
  components: {
    cardTitleCom,
    comTable,
    Dialog,
    SelectRemote
  },
  data() {
    return {
      forbiddenTime: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      caseIndex: 0,
      openType: null,
      //弹窗配置
      dialogOptions: {
        title: "选择商品",
        width: 1250,
        show: false,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "goods" }),
      },
      //禁用
      disabled: false,
      //表格遮罩
      loadingTable: false,
      //整体遮罩
      loading: false,
      //表单
      commissionForm: {
        datetime: [],
        distributionType: 1,
        vipLevelRangeType: 0,
        billLevelDetailItems: [], //适用会员表格数据
        vipDistributionManCommissionDetailItems: [{
          billGoodsDetailItems: [{ commissionCalcType: 1 }], //销售提成表格
        }], //分销员提成设置
      },
      //旧的表单
      oldForm: {},
      // 表单校验
      rules: {
        billNo: [
          {
            required: true,
            message: '方案编号不能为空',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^[a-zA-Z0-9]+$/,
            message: '只能输入数字和英文字符',
            trigger: ['blur', 'change']
          }
        ],
        billName: [
          {
            required: true,
            message: '方案名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        datetime: [
          {
            required: true,
            message: '有效时间不能为空',
            trigger: ['blur', 'change']
          }
        ],
        // goodsRangeType: [
        //   {
        //     required: true,
        //     message: '提成范围不能为空',
        //     trigger: ['blur', 'change']
        //   }
        // ],
        'vipDistributionManCommissionDetailItems.distributionLevelId': [
          {
            required: true,
            message: '分销员等级不能为空',
            trigger: ['blur', 'change']
          }
        ],
        vipLevelRangeType: [
          {
            required: true,
            message: '适用会员不能为空',
            trigger: ['blur', 'change']
          }
        ],
        // 'billGoodsDetailItems.lineNo': [
        //   {
        //     required: true,
        //     message: '编码不能为空',
        //     trigger: ['blur', 'change']
        //   }
        // ],
        // 'billGoodsDetailItems.lineName': [
        //   {
        //     required: true,
        //     message: '名称不能为空',
        //     trigger: ['blur', 'change']
        //   }
        // ],
        // 'billGoodsDetailItems.unitId': [
        //   {
        //     required: true,
        //     message: '单位不能为空',
        //     trigger: ['blur', 'change']
        //   }
        // ],
        // 'billGoodsDetailItems.commissionCalcType': [
        //   {
        //     required: true,
        //     message: '提成方式不能为空',
        //     trigger: ['blur', 'change']
        //   }
        // ],
        // 'billGoodsDetailItems.commissionSaleRate1': [
        //   {
        //     required: true,
        //     message: '销售提成率不能为空',
        //     trigger: ['blur', 'change']
        //   },
        //   {
        //     pattern: /^\d+(\.\d+)?$/,
        //     message: '只能输入大于等于0的数字',
        //     trigger: ['blur', 'change']
        //   }
        // ],
        // 'billGoodsDetailItems.commissionSaleOneMoney1': [
        //   {
        //     required: true,
        //     message: '按数量提成不能为空',
        //     trigger: ['blur', 'change']
        //   },
        //   {
        //     pattern: /^\d+(\.\d+)?$/,
        //     message: '只能输入大于等于0的数字',
        //     trigger: ['blur', 'change']
        //   }
        // ],
        // 'billGoodsDetailItems.commissionSaleRate2': [
        //   {
        //     required: true,
        //     message: '销售提成率不能为空',
        //     trigger: ['blur', 'change']
        //   },
        //   {
        //     pattern: /^\d+(\.\d+)?$/,
        //     message: '只能输入大于等于0的数字',
        //     trigger: ['blur', 'change']
        //   }
        // ],
        // 'billGoodsDetailItems.commissionSaleOneMoney2': [
        //   {
        //     required: true,
        //     message: '按数量提成不能为空',
        //     trigger: ['blur', 'change']
        //   },
        //   {
        //     pattern: /^\d+(\.\d+)?$/,
        //     message: '只能输入大于等于0的数字',
        //     trigger: ['blur', 'change']
        //   }
        // ]
      },
      //会员级别
      vipTable: false,
      //会员表格字段
      vipTableLable: [
        { label: "编号", prop: "lineNo" },
        { label: "名称", prop: "lineName" },
      ],
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.loading = true
      // 重置数据
      await vm.reset()
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === 'Update') {
        if (vm.$route.query.billId) {
          //获取方案详情
          const { data } = await commissionDetailAPI(vm.$route.query.billId)
          //方案赋值
          data.datetime = [data.begTime, data.endTime];
          vm.commissionForm = data
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.commissionForm))
        }
      } else {
        //初始化数据
        vm.commissionForm.billDate = getTimeDate()
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != '{}') {
          vm.commissionForm = vm.oldForm
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.commissionForm))
      }
      vm.loading = false
    })
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.commissionForm.billId) {
      this.oldForm = {}
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.commissionForm))
    }
    next()
  },
  watch: {
    //侦听适用对象
    "commissionForm.vipLevelRangeType": {
      handler(val) {
        if (val) {
          //当选中全部会员+排除/指定会员时 显示会员表格 反之隐藏
          if (val === 1 || val === 2) {
            this.vipTable = true;
          } else {
            this.vipTable = false;
          }
        } else {
          this.vipTable = false;
        }
      },
      immediate: true,
    },
    "commissionForm.billStatus": {
      handler(val) {
        if (val == '2') {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    addDistributionLevel(index) {
      this.commissionForm.vipDistributionManCommissionDetailItems.splice(index + 1, 0, {
        billGoodsDetailItems: [{ commissionCalcType: 1 }], //销售提成表格
      });
    },
    delDistributionLevel(index) {
      if (this.commissionForm.vipDistributionManCommissionDetailItems.length == 1) {
        this.commissionForm.vipDistributionManCommissionDetailItems = [{
          billGoodsDetailItems: [{ commissionCalcType: 1 }], //销售提成表格
        }]
      } else {
        this.commissionForm.vipDistributionManCommissionDetailItems.splice(index, 1)
      }
    },
    async openDialog(type) {
      this.openType = type;
      switch (type) {
        case "vip":
          this.dialogOptions.title = "选择会员";
          this.dialogOptions.formData = this.$dialog({ key: "vip" });
          this.dialogOptions.show = true;
          break;
        case "vipLevel":
          this.dialogOptions.title = "选择会员级别";
          this.dialogOptions.formData = this.$dialog({ key: "vipLevel" });
          this.dialogOptions.show = true;
          break;
        case "goods":
          this.dialogOptions.title = "选择商品";
          this.dialogOptions.formData = this.$dialog({ key: "goods" });
          this.dialogOptions.show = true;
          break;
        case "category":
          this.dialogOptions.title = "选择商品类别";
          this.dialogOptions.formData = this.$dialog({ key: "category" });
          this.dialogOptions.show = true;
          break;
        case "brand":
          this.dialogOptions.title = "选择商品品牌";
          this.dialogOptions.formData = this.$dialog({ key: "brand" });
          this.dialogOptions.show = true;
          break;
        default:
          break;
      }
    },
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          if (this.openType == "vip") {
            this.getPitchData(this.dialogOptions.formData.table.check, 'vipItem')
          } else if (this.openType == "vipLevel") {
            this.getPitchData(this.dialogOptions.formData.table.check, 'vipRank')
          } else if (this.openType == "goods") {
            this.getGoodS(this.dialogOptions.formData.table.check)
          } else if (this.openType == "category") {
            this.categoryList(this.dialogOptions.formData.table.check)
          } else if (this.openType == "brand") {
            this.brandList(this.dialogOptions.formData.table.check)
          }
          break;
        default:
          break;
      }
    },
    distributionLevelChnage(row) {
      // let arr = this.commissionForm.vipDistributionManCommissionDetailItems[this.caseIndex].billGoodsDetailItems.filter(x => x.lineId == row.lineId && x.distributionLevelId == row.distributionLevelId && x.unitId == row.unitId)
      // if (arr.length > 1) {
      //   this.$set(row, 'distributionLevelId', '')
      //   this.$message.error('该会员级别已存在，请重新选择！')
      // }
    },
    //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/vipcard/distribution/commissionCase" });
    },
    //获取编号事件
    getNewBillNo,
    //选择商品方法
    selectLineType(index, row) {
      this.caseIndex = index
      if (row.goodsRangeType == 1) {
        //商品
        this.openDialog('goods')
      } else if (row.goodsRangeType == 2) {
        //商品类别
        this.openDialog('category')
      } else if (row.goodsRangeType == 3) {
        //商品品牌
        this.openDialog('brand')
      }
    },
    //选中的商品品牌
    brandList(value) {
      if (value.length == 0) return
      //去重
      value = value.filter(item => {
        return this.commissionForm.vipDistributionManCommissionDetailItems[this.caseIndex].billGoodsDetailItems.every(
          v => v.lineId != item.brandId
        )
      })
      if (value.length == 0) return
      value = value.map(v => {
        let obj = {
          lineType: 2,
          lineId: v.brandId,
          lineNo: v.brandNo,
          lineName: v.brandName,
          commissionCalcType: 1
        }
        return obj
      })
      this.commissionForm.vipDistributionManCommissionDetailItems[this.caseIndex].billGoodsDetailItems.push(...value)
      this.commissionForm.vipDistributionManCommissionDetailItems[this.caseIndex].billGoodsDetailItems =
        this.commissionForm.vipDistributionManCommissionDetailItems[this.caseIndex].billGoodsDetailItems.filter(
          item =>
            (!(JSON.stringify(item) === '{}') &&
              !(item.lineId === undefined)) ||
            !(item.lineId === undefined)
        )
    },
    //选中的商品类别
    categoryList(value) {
      if (value.length == 0) return
      //去重
      value = value.filter(item => {
        return this.commissionForm.vipDistributionManCommissionDetailItems[this.caseIndex].billGoodsDetailItems.every(
          v => v.lineId != item.categoryId
        )
      })
      if (value.length == 0) return
      value = value.map(v => {
        let obj = {
          lineType: 3,
          lineId: v.categoryId,
          lineNo: v.categoryNo,
          lineName: v.categoryName,
          commissionCalcType: 1
        }
        return obj
      })
      this.commissionForm.vipDistributionManCommissionDetailItems[this.caseIndex].billGoodsDetailItems.push(...value)
      this.commissionForm.vipDistributionManCommissionDetailItems[this.caseIndex].billGoodsDetailItems =
        this.commissionForm.vipDistributionManCommissionDetailItems[this.caseIndex].billGoodsDetailItems.filter(
          item =>
            (!(JSON.stringify(item) === '{}') &&
              !(item.lineId === undefined)) ||
            !(item.lineId === undefined)
        )
    },
    //选中的商品
    getGoodS(value) {
      if (value.length == 0) return
      //去重
      value = value.filter(item => {
        return this.commissionForm.vipDistributionManCommissionDetailItems[this.caseIndex].billGoodsDetailItems.every(
          v => v.lineId != item.goodsId
        )
      })
      if (value.length == 0) return
      value = value.map(v => {
        let obj = {
          lineType: 1,
          lineId: v.goodsId,
          lineNo: v.goodsNo,
          lineName: v.goodsName,
          unitId: v.unitId,
          unitName: v.unitName,
          units: v.units,
          commissionCalcType: 1
        }
        return obj
      })
      this.commissionForm.vipDistributionManCommissionDetailItems[this.caseIndex].billGoodsDetailItems.push(...value)
      this.commissionForm.vipDistributionManCommissionDetailItems[this.caseIndex].billGoodsDetailItems =
        this.commissionForm.vipDistributionManCommissionDetailItems[this.caseIndex].billGoodsDetailItems.filter(
          item =>
            (!(JSON.stringify(item) === '{}') &&
              !(item.lineId === undefined)) ||
            !(item.lineId === undefined)
        )
    },
    //商品类型切换
    goodsRangeTypeChange(val, index) {
      if (val == 0) {
        this.commissionForm.vipDistributionManCommissionDetailItems[index].billGoodsDetailItems = [{ commissionCalcType: 1, lineId: 0, lineType: 0 }]
      } else {
        this.commissionForm.vipDistributionManCommissionDetailItems[index].billGoodsDetailItems = [{ commissionCalcType: 1 }]
      }
    },
    //提成方式切换
    commissionCalcTypeChange(row, index) {
      this.$set(row, 'commissionSaleOneMoney1', undefined)
      this.$set(row, 'commissionSaleRate1', undefined)
      this.$set(row, 'commissionSaleOneMoney2', undefined)
      this.$set(row, 'commissionSaleRate2', undefined)
    },
    //删除表格行事件(共同删除事件,以type区分表格(门店/商品/会员/时间))
    delTableRow(row, type, index) {
      if (type === "shop") {
        //门店
        //过滤掉删除的数据行
        this.commissionForm.shopItem = this.commissionForm.shopItem.filter((v) => v.shopId !== row.shopId);
      } else if (type === "comboGoodsItem") {
        this.commissionForm.comboItem[index].comboGoodsItem = this.commissionForm.comboItem[
          index
        ].comboGoodsItem.filter((v) => v.lineId !== row.lineId);
      } else if (type === "optionalGoodsItem") {
        this.commissionForm.optionalGoodsItem.splice(row.rowIndex, 1);
      } else if (type === "goods") {
        //商品
        //过滤掉删除的数据行
        this.commissionForm.goodsItem = this.commissionForm.goodsItem.filter((v) => v.lineId !== row.lineId);
      } else if (type === "vip") {
        //会员
        //过滤掉删除的数据行
        this.commissionForm.billLevelDetailItems = this.commissionForm.billLevelDetailItems.filter(
          (v) => v.lineId !== row.lineId
        );
      } else if (type === "time") {
        //时间
        //过滤掉删除的数据行
        this.specialDate = this.specialDate.filter((v) => v.id !== row.id); //累加的时间数组
        this.commissionForm.specialDateItem = this.commissionForm.specialDateItem.filter(
          (v) => v.id !== row.id
        );
      }
    },

    //获取选中的数据赋值表格    type区分类型(门店/商品/会员/时间/优惠券)
    getPitchData(data, type) {
      let tableData = []; //定义一个数组变量 (存放不同表格)
      let id; //定义一个变量存放不同id名称
      if (type === "vipItem" || type === "vipRank") {
        //(会员/会员级别)
        tableData = this.commissionForm.billLevelDetailItems;
        id = "lineId"; //(会员/会员级别)公用id
      }
      //过滤选中数组跟表格里重复的数据
      if (tableData.length >= 1) {
        //当表格数据存在时
        for (let i = 0; tableData.length > i; i++) {
          data = data.filter((v) => {
            //商品表格/会员表格需要添加上类型
            if (type === "vipItem") {
              //会员
              v.lineType = 1;
              v.lineId = v.vipId; //会员表格行统一id
              v.lineNo = v.vipNo; //统一编号字段
              v.lineName = v.vipName; //统一名称字段
            } else if (type === "vipRank") {
              //会员级别
              v.lineType = 2;
              v.lineId = v.vipLevelId;
              v.lineNo = v.vipLevelNo; //统一编号字段
              v.lineName = v.vipLevelName; //统一名称字段
            }
            if (type !== "goodsItem") {
              return v[id] !== tableData[i][id]; //选中的数据(门店/商品/会员) 过滤掉已经在表格中已经存在的数据
            } else {
              return v;
            }
          });
        }
        //当是数量特价方案时,选择商品是单选,直接赋值,并添加上一行空规则数组
        if (this.commissionForm.promoType === "1") {
          console.log(data);
          this.$set(this.commissionForm.goodsItem, this.clickGoodsIndex, data[0]);
          return this.$set(
            this.commissionForm.goodsItem[this.clickGoodsIndex],
            "goodsBuyFullItem",
            [{}]
          );
        }
        tableData.push(...data); //再追加在表格里
      } else {
        //当表格数据为空时,直接追加表格里
        data.forEach((v) => {
          //商品表格/会员表格需要添加上类型
          if (type === "vipItem") {
            //会员
            v.lineType = 1;
            v.lineId = v.vipId; //会员表格行统一id
            v.lineNo = v.vipNo; //统一编号字段
            v.lineName = v.vipName; //统一名称字段
          } else if (type === "vipRank") {
            //会员级别
            v.lineType = 2;
            v.lineId = v.vipLevelId;
            v.lineNo = v.vipLevelNo; //统一编号字段
            v.lineName = v.vipLevelName; //统一名称字段
          }
          tableData.push(v);
        });
      }
    },
    //表格增加/减少一行方法
    row(name, index, lineIndex) {
      if (name === 'push') {
        this.commissionForm.vipDistributionManCommissionDetailItems[lineIndex].billGoodsDetailItems.splice(index + 1, 0, {
          commissionCalcType: 1
        })
      } else {
        if (this.commissionForm.vipDistributionManCommissionDetailItems[lineIndex].billGoodsDetailItems.length <= 1) {
          this.commissionForm.vipDistributionManCommissionDetailItems[lineIndex].billGoodsDetailItems = [{ commissionCalcType: 1 }]
        } else {
          this.commissionForm.vipDistributionManCommissionDetailItems[lineIndex].billGoodsDetailItems.splice(index, 1)
        }
      }
    },
    // 表单重置
    async reset() {
      this.commissionForm = {
        datetime: [],
        distributionType: 1,
        vipLevelRangeType: 0,
        billLevelDetailItems: [], //适用会员表格数据
        vipDistributionManCommissionDetailItems: [{
          billGoodsDetailItems: [{ commissionCalcType: 1 }], //销售提成表格
        }],
      }
      await this.resetForm('form')
      this.commissionForm.billNo = await this.getNewBillNo(this.commissionForm, "billNo", "150401");
    },
    /** 提交按钮 */
    async submitForm(bool, isAudit) {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          const arr = deepCopy(this.commissionForm.vipDistributionManCommissionDetailItems);
          console.log('分销员提成方案明细：', deepCopy(arr));
          const validations = {
            distributionLevelId: "分销员等级！",
            goodsRangeType: "提成范围！",
          };
          let formIndex = 1; // 从1开始计数，因为用户期望看到“第1项”这样的提示
          for (const formItem of arr) {
            for (const validationKey in validations) {
              if ((!formItem[validationKey] && formItem[validationKey] !== 0) || formItem[validationKey] === '') {
                return this.$message.error(`请选择第${formIndex}项${validations[validationKey]}`);
              }
            }
            formIndex++;
          }
          this.commissionForm.begTime = this.commissionForm.datetime[0];
          this.commissionForm.endTime = this.commissionForm.datetime[1];
          let obj = JSON.parse(JSON.stringify(this.commissionForm));
          obj.billLevelDetailItems = this.commissionForm.billLevelDetailItems.map(item => ({
            isExcl: this.commissionForm.vipLevelRangeType == 1,
            lineId: item.lineId,
            lineType: item.lineType
          }))
          obj.billDate = getTimeDate()
          let text = this.commissionForm.billId ? '修改' : '新增';
          let res = undefined;
          if (this.commissionForm.billId) {
            res = await commissionUpdateAPI(obj)
          } else {
            res = await commissionAddAPI(obj)
          }
          this.commissionForm = res.data;
          this.commissionForm.datetime = [this.commissionForm.begTime, this.commissionForm.endTime];
          if (bool) {
            this.reset();
            this.commissionForm.billDate = getTimeDate()
            this.$modal.msgSuccess('保存并新增成功')
          } else {
            this.$modal.msgSuccess(text + '方案成功')
            if (isAudit) {
              this.submitAudit();
            }
          }
        }
      })
    },
    //审核/反审核按钮 type 0是反审核 2是审核
    async submitAudit(type) {
      let billIds = this.commissionForm.billId;
      //当id为空时,先保存再审核
      if (!billIds) {
        billIds = await this.submitForm(false, true);
      }
      let typeName = type === "反审核" ? "反审核成功" : "审核成功";
      if (type === "审核" && this.form.billStatus != "0")
        return this.$message.error("此单据不是未审核的状态哦~");
      if (type === "反审核" && this.form.billStatus != "2")
        return this.$message.error("此单据不是已审核的状态哦~");
      if (!billIds) {
        return;
      }
      updateBillStatus([billIds], type === "反审核" ? "0" : "2", '/api/system/vip/distribution/commission/updateBillStatus')
        .then((res) => {
          this.commissionForm = res.data;
          this.commissionForm.datetime = [this.commissionForm.begTime, this.commissionForm.endTime];
          this.$message.success(typeName);
        });
    },
  }
}
  </script>
  
  <style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  min-height: calc(100vh - 84px);
  // 顶部按钮
  .btnTOP {
    text-align: right;
    padding: 0 10px 6px 0;
  }
  //基本信息内容
  .tableContentTop {
    padding: 6px 10px;
  }
  .inputWidthM {
    width: 200px;
  }
  //表格
  .tableContent {
    padding: 6px 10px;
    ::v-deep .el-form-item__content {
      margin-left: 0 !important;
    }
    ::v-deep .el-form-item__error {
      position: static;
    }
    ::v-deep .el-form-item {
      margin: 0 !important;
      padding: 0 !important;
      display: flex;
    }
  }
}
.vipBtn {
  margin-bottom: 10px;
}
.sWidth {
  width: 150px !important;
}

::v-deep input[aria-hidden="true"] {
  display: none !important;
}

::v-deep
  .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled)
  .el-radio__inner {
  box-shadow: none !important;
}
</style>
  