<!-- 分销会员提成报表 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { distributionVipCommission, distributionVipCommissionSummary} from "@/api/vip/distribution/commissionReport"; //分销员子会员查询

export default {
  name: "room",
  components: { TablePage },
  data() {
    return {
      options: {
        uuid: "68c12ff4-e8b5-1038-f285-81db3a1310c0",
        summary: ["unitQty", "unitTaxMoney", "unitPriceTaxMoney"],
        mutiSelect: false, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        getListApi: distributionVipCommission,
        getSummaryApi: distributionVipCommissionSummary,
        exportOption: {
          fastExportUrl: "/api/system/vip/distribution/reportForms/distributionVipCommissionExport",
          exportName: "分销员会员提成报表",
        },
        body: {},
        listNo: true, // 序号
        title: "分销员会员提成报表",
        check: [], // 选中数据
        rowKey: "1703967014381789184",
        search: [
          {
            label: "业务时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "分销员编号/分销员名称/单据号",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "distributionManVipNos", label: "分销员编号" },
              { filter: "distributionManVipNames", label: "分销员名称" },
              { filter: "billNos", label: "单据号" },
            ],
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          {
            prop: "vipNo",
            label: "会员编号",
            minWidth: 180,
          },
          {
            prop: "vipName",
            label: "会员名称",
            minWidth: 130,
          },
          {
            prop: "vipLevelNo",
            label: "会员等级编号",
            minWidth: 120,
          },
          {
            prop: "vipLevelName",
            label: "会员等级名称",
            minWidth: 160,
          },
          {
            prop: "goodsNo",
            label: "商品编号",
            minWidth: 150,
          },
          {
            prop: "goodsName",
            label: "商品名称",
            minWidth: 150,
          },
          {
            prop: "shopNo",
            label: "门店编号",
            minWidth: 130,
          },
          {
            prop: "shopName",
            label: "门店名称",
            minWidth: 130,
          },
          {
            prop: "billNo",
            label: "小票号",
            minWidth: 180,
          },
          {
            prop: "cashierName",
            label: "收银员",
            minWidth: 130,
          },
          {
            prop: "machineName",
            label: "收银机名称",
            minWidth: 130,
          },
          {
            prop: "optCommission",
            label: "操作提成",
            minWidth: 130,
          },
          {
            prop: "endCommission",
            label: "剩余提成",
            minWidth: 130,
          },
          {
            prop: "unitName",
            label: "单位",
            minWidth: 130,
          },
          {
            prop: "unitQty",
            label: "单位数量",
            minWidth: 130,
          },
          {
            prop: "unitMoney",
            label: "金额",
            minWidth: 130,
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 130,
          },
          {
            prop: "createTime",
            label: "业务时间",
            minWidth: 155,
          },
        ],
        summary: [
          'endCommission',
          'optCommission',
          "unitQty",
          "unitMoney"
        ],
        list: [],
      },
    };
  },
  methods: {
    seniorSearch() {},
  },
};
</script>
<style lang="scss" scoped></style>
