var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.commissionForm,
            rules: _vm.rules,
            "label-width": "90px",
          },
        },
        [
          _c(
            "div",
            { staticClass: "btnTOP" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: _vm.disabled,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(false)
                    },
                  },
                },
                [_vm._v("保存 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: _vm.disabled,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(true)
                    },
                  },
                },
                [_vm._v("保存并新增 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: _vm.disabled,
                  },
                  on: { click: _vm.submitAudit },
                },
                [_vm._v("审核 ")]
              ),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.getQuit } },
                [_vm._v("退出")]
              ),
            ],
            1
          ),
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "基本信息",
                billStatus: _vm.commissionForm.billStatus,
              },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContentTop x-w" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "方案编号", prop: "billNo" } },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "mini",
                            maxlength: "20",
                            disabled: "",
                          },
                          model: {
                            value: _vm.commissionForm.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.commissionForm, "billNo", $$v)
                            },
                            expression: "commissionForm.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "方案名称", prop: "billName" } },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "mini",
                            maxlength: "40",
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.commissionForm.billName,
                            callback: function ($$v) {
                              _vm.$set(_vm.commissionForm, "billName", $$v)
                            },
                            expression: "commissionForm.billName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "有效时间", prop: "datetime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            size: "mini",
                            type: "datetimerange",
                            "start-placeholder": "开始时间",
                            "end-placeholder": "结束时间",
                            "default-time": ["00:00:00", "23:59:59"],
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            disabled: _vm.disabled,
                            "picker-options": _vm.forbiddenTime,
                          },
                          model: {
                            value: _vm.commissionForm.datetime,
                            callback: function ($$v) {
                              _vm.$set(_vm.commissionForm, "datetime", $$v)
                            },
                            expression: "commissionForm.datetime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "分销模式", prop: "distributionType" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择分销模式",
                              size: "mini",
                              disabled: _vm.disabled,
                            },
                            model: {
                              value: _vm.commissionForm.distributionType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.commissionForm,
                                  "distributionType",
                                  $$v
                                )
                              },
                              expression: "commissionForm.distributionType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "一级分销", value: 1 },
                            }),
                            _c("el-option", {
                              attrs: { label: "二级分销", value: 2 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          staticClass: "inputRemark",
                          attrs: {
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            autosize: { minRows: 1, maxRows: 5 },
                            size: "mini",
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.commissionForm.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.commissionForm, "billRemark", $$v)
                            },
                            expression: "commissionForm.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "提成类型" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "tableContent marL15" }, [
                  _c(
                    "div",
                    _vm._l(
                      _vm.commissionForm
                        .vipDistributionManCommissionDetailItems,
                      function (item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c(
                              "div",
                              { staticClass: "marB10 x-f" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-right": "10px",
                                      "font-size": "14px",
                                    },
                                  },
                                  [
                                    _c(
                                      "i",
                                      { staticStyle: { color: "#ff4949" } },
                                      [_vm._v("* ")]
                                    ),
                                    _c("span", [_vm._v("分销员等级")]),
                                  ]
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "distributionLevelId" } },
                                  [
                                    _c("SelectRemote", {
                                      attrs: {
                                        option: _vm.$select({
                                          key: "listDistributionManLeve",
                                          option: {
                                            option: {
                                              disabled: _vm.disabled,
                                              showItem: [
                                                {
                                                  distributionLevelId:
                                                    item.distributionLevelId,
                                                  distributionLevelName:
                                                    item.distributionLevelName,
                                                },
                                              ],
                                            },
                                          },
                                        }).option,
                                      },
                                      on: {
                                        change: _vm.distributionLevelChnage,
                                      },
                                      model: {
                                        value: item.distributionLevelId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item,
                                            "distributionLevelId",
                                            $$v
                                          )
                                        },
                                        expression: "item.distributionLevelId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      margin: "0 10px",
                                      "font-size": "14px",
                                    },
                                  },
                                  [
                                    _c(
                                      "i",
                                      { staticStyle: { color: "#ff4949" } },
                                      [_vm._v("* ")]
                                    ),
                                    _c("span", [_vm._v("提成范围")]),
                                  ]
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "goodsRangeType" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择提成范围",
                                          filterable: "",
                                          clearable: "",
                                          size: "mini",
                                          disabled: _vm.disabled,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.goodsRangeTypeChange(
                                              $event,
                                              index
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.goodsRangeType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "goodsRangeType",
                                              $$v
                                            )
                                          },
                                          expression: "item.goodsRangeType",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "全部商品",
                                            value: 0,
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "指定商品",
                                            value: 1,
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "指定类别",
                                            value: 2,
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "指定品牌",
                                            value: 3,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "20px" },
                                    attrs: {
                                      size: "mini",
                                      type: "primary",
                                      icon: "el-icon-plus",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addDistributionLevel(index)
                                      },
                                    },
                                  },
                                  [_vm._v("添加等级")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: "danger",
                                      icon: "el-icon-delete",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delDistributionLevel(index)
                                      },
                                    },
                                  },
                                  [_vm._v("删除等级")]
                                ),
                              ],
                              1
                            ),
                            item.goodsRangeType != undefined
                              ? _c(
                                  "el-table",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.loadingTable,
                                        expression: "loadingTable",
                                      },
                                    ],
                                    ref: "multipleTable",
                                    refInFor: true,
                                    staticClass: "marB10",
                                    attrs: {
                                      "tooltip-effect": "dark",
                                      data: item.billGoodsDetailItems,
                                      border: "",
                                      "max-height": "500",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "序号",
                                        type: "index",
                                        width: "80",
                                        "class-name": "allowDrag",
                                        align: "center",
                                      },
                                    }),
                                    !_vm.disabled && item.goodsRangeType != 0
                                      ? _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "操作",
                                            width: "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-circle-plus operatePush",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.row(
                                                            "push",
                                                            scope.$index,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-remove operateDel",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.row(
                                                            "del",
                                                            scope.$index,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        })
                                      : _vm._e(),
                                    item.goodsRangeType != 0
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "类型",
                                            align: "center",
                                            prop: "lineType",
                                            width: "130",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("dict-tag", {
                                                      attrs: {
                                                        options:
                                                          _vm.dict.type
                                                            .vip_applicable_commodities_appoint_type,
                                                        value:
                                                          scope.row.lineType,
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        })
                                      : _vm._e(),
                                    item.goodsRangeType != 0
                                      ? _c(
                                          "el-table-column",
                                          {
                                            attrs: {
                                              label: "编码",
                                              width: "180",
                                              align: "center",
                                              prop: "lineNo",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "el-form-item",
                                                        [
                                                          _c(
                                                            "el-input",
                                                            {
                                                              attrs: {
                                                                size: "mini",
                                                                disabled:
                                                                  _vm.disabled,
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .lineNo,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "lineNo",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.lineNo",
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-more more",
                                                                attrs: {
                                                                  slot: "suffix",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.selectLineType(
                                                                        index,
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                                slot: "suffix",
                                                              }),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("template", { slot: "header" }, [
                                              _c(
                                                "i",
                                                {
                                                  staticStyle: {
                                                    color: "#ff4949",
                                                  },
                                                },
                                                [_vm._v("* ")]
                                              ),
                                              _c("span", [_vm._v("编码")]),
                                            ]),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    item.goodsRangeType != 0
                                      ? _c("el-table-column", {
                                          attrs: {
                                            label: "名称",
                                            align: "center",
                                            prop: "lineName",
                                            width: "130",
                                          },
                                        })
                                      : _vm._e(),
                                    item.goodsRangeType == 1
                                      ? _c(
                                          "el-table-column",
                                          {
                                            attrs: {
                                              label: "单位",
                                              align: "center",
                                              prop: "unitId",
                                              width: "170",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "el-form-item",
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "146px",
                                                              },
                                                              attrs: {
                                                                size: "mini",
                                                                placeholder:
                                                                  "请选择单位",
                                                                disabled:
                                                                  _vm.disabled,
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .unitId,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "unitId",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.unitId",
                                                              },
                                                            },
                                                            _vm._l(
                                                              scope.row.units,
                                                              function (dict) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: dict.unitId,
                                                                    attrs: {
                                                                      label:
                                                                        dict.unitName,
                                                                      value:
                                                                        dict.unitId,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("template", { slot: "header" }, [
                                              _c(
                                                "i",
                                                {
                                                  staticStyle: {
                                                    color: "#ff4949",
                                                  },
                                                },
                                                [_vm._v("* ")]
                                              ),
                                              _c("span", [_vm._v("单位")]),
                                            ]),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          label: "提成方式",
                                          width:
                                            _vm.commissionForm
                                              .distributionType === 2
                                              ? 490
                                              : 325,
                                          align: "center",
                                          prop: "commissionCalcType",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "div",
                                                    { staticClass: "x-x" },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticClass:
                                                                "marR10",
                                                              staticStyle: {
                                                                width: "146px",
                                                              },
                                                              attrs: {
                                                                size: "mini",
                                                                placeholder:
                                                                  "请选择提成方式",
                                                                clearable: "",
                                                                disabled:
                                                                  _vm.disabled,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.commissionCalcTypeChange(
                                                                      scope.row,
                                                                      1
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .commissionCalcType,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "commissionCalcType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.commissionCalcType",
                                                              },
                                                            },
                                                            [
                                                              _c("el-option", {
                                                                attrs: {
                                                                  label:
                                                                    "销售提成率(%)",
                                                                  value: 1,
                                                                },
                                                              }),
                                                              _c("el-option", {
                                                                attrs: {
                                                                  label:
                                                                    "按数量提成(个)",
                                                                  value: 2,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      scope.row
                                                        .commissionCalcType == 1
                                                        ? _c(
                                                            "el-form-item",
                                                            [
                                                              _c("el-input", {
                                                                staticClass:
                                                                  "inputWidth sWidth",
                                                                attrs: {
                                                                  size: "mini",
                                                                  oninput:
                                                                    "value=value.replace(/[^0-9.]/g,'')",
                                                                  clearable: "",
                                                                  disabled:
                                                                    _vm.disabled,
                                                                  placeholder:
                                                                    "一级销售提成率",
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .commissionSaleRate1,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "commissionSaleRate1",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.commissionSaleRate1",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      scope.row
                                                        .commissionCalcType == 2
                                                        ? _c(
                                                            "el-form-item",
                                                            [
                                                              _c("el-input", {
                                                                staticClass:
                                                                  "inputWidth sWidth",
                                                                attrs: {
                                                                  size: "mini",
                                                                  oninput:
                                                                    "value=value.replace(/[^0-9.]/g,'')",
                                                                  clearable: "",
                                                                  disabled:
                                                                    _vm.disabled,
                                                                  placeholder:
                                                                    "一级按数量提成",
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .commissionSaleOneMoney1,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "commissionSaleOneMoney1",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.commissionSaleOneMoney1",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      scope.row
                                                        .commissionCalcType ==
                                                        1 &&
                                                      _vm.commissionForm
                                                        .distributionType === 2
                                                        ? _c(
                                                            "el-form-item",
                                                            [
                                                              _c("el-input", {
                                                                staticClass:
                                                                  "inputWidth sWidth",
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "10px",
                                                                },
                                                                attrs: {
                                                                  size: "mini",
                                                                  oninput:
                                                                    "value=value.replace(/[^0-9.]/g,'')",
                                                                  clearable: "",
                                                                  disabled:
                                                                    _vm.disabled,
                                                                  placeholder:
                                                                    "二级销售提成率",
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .commissionSaleRate2,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "commissionSaleRate2",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.commissionSaleRate2",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      scope.row
                                                        .commissionCalcType ==
                                                        2 &&
                                                      _vm.commissionForm
                                                        .distributionType === 2
                                                        ? _c(
                                                            "el-form-item",
                                                            [
                                                              _c("el-input", {
                                                                staticClass:
                                                                  "inputWidth sWidth",
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "10px",
                                                                },
                                                                attrs: {
                                                                  size: "mini",
                                                                  oninput:
                                                                    "value=value.replace(/[^0-9.]/g,'')",
                                                                  clearable: "",
                                                                  disabled:
                                                                    _vm.disabled,
                                                                  placeholder:
                                                                    "二级按数量提成",
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .commissionSaleOneMoney2,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "commissionSaleOneMoney2",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.commissionSaleOneMoney2",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("template", { slot: "header" }, [
                                          _c(
                                            "i",
                                            {
                                              staticStyle: { color: "#ff4949" },
                                            },
                                            [_vm._v("* ")]
                                          ),
                                          _c("span", [_vm._v("提成方式")]),
                                        ]),
                                      ],
                                      2
                                    ),
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        prop: "goodsNo",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "适用会员",
                            prop: "vipLevelRangeType",
                            "label-width": "80px",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.commissionForm.vipLevelRangeType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.commissionForm,
                                    "vipLevelRangeType",
                                    $$v
                                  )
                                },
                                expression: "commissionForm.vipLevelRangeType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("全部会员"),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("全部会员+排除"),
                              ]),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("指定会员"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.vipTable
                        ? _c(
                            "div",
                            { staticClass: "selectVip" },
                            [
                              _c(
                                "div",
                                { staticClass: "vipBtn" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openDialog("vip")
                                        },
                                      },
                                    },
                                    [_vm._v("选择会员 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openDialog("vipLevel")
                                        },
                                      },
                                    },
                                    [_vm._v("选择会员等级 ")]
                                  ),
                                ],
                                1
                              ),
                              _c("comTable", {
                                staticClass: "marB10",
                                attrs: {
                                  columnLabel: _vm.vipTableLable,
                                  tableData:
                                    _vm.commissionForm.billLevelDetailItems,
                                  disabled: _vm.disabled,
                                },
                                on: {
                                  delTableRow: function ($event) {
                                    return _vm.delTableRow($event, "vip")
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "tableMiddle",
                                      fn: function () {
                                        return [
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "类型",
                                              align: "center",
                                              prop: "lineType",
                                              width: "123",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("dict-tag", {
                                                        attrs: {
                                                          options:
                                                            _vm.dict.type
                                                              .member_type,
                                                          value:
                                                            scope.row.lineType,
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              889154850
                                            ),
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1129299638
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }